import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Form, Container, Col, Row } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import NotificationAlert from "react-notification-alert";

import { minLength, emailValidation } from "../../utils/validators";

function LoginPage() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [emailState, setEmailState] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordState, setPasswordState] = useState(true);
  const notificationAlertRef = useRef(null);
  const defaultNotificationOptions = {
    place: "tr",
    message: "Success",
    type: "success",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 3,
  };

  const onSubmit = () => {
    if (!emailState || !emailValidation(email)) {
      setEmailState(false);
    } else {
      setEmailState(true);
    }
    if (!passwordState || !minLength(password, 1)) {
      setPasswordState(false);
    } else {
      setPasswordState(true);
    }

    if (
      !emailState ||
      !emailValidation(email) ||
      !passwordState ||
      !minLength(password, 1)
    )
      return;

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/admin/account/login`,
      data: {
        username: email,
        password: password,
      },
    })
      .then((res) => {
        if (res.status === 200 && res.data.code === "SUCCESS" ) {
          const token = res.data.data.accessToken;
          const user = res.data.data.user;
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user.email)); 
          window.location.href=("/admin/users")
        } else if (res.status === 200 && res.data.code === "INVALID_CREDENTIALS" ) {
          const options = {
            ...defaultNotificationOptions,
            message: "Invalid credentials",
            type: "danger",
            autoDismiss: 5,
          };
          notificationAlertRef.current.notificationAlert(options);
        } else {
          const options = {
            ...defaultNotificationOptions,
            message: "Something went wrong. Please try again later.",
            type: "danger",
            autoDismiss: 5,
          };
          notificationAlertRef.current.notificationAlert(options);
        }
      })
      .catch((error) => {
        console.log("error", error)
        const options = {
          ...defaultNotificationOptions,
          message: "Invalid credentials",
          type: "danger",
          autoDismiss: 5,
        };
        notificationAlertRef.current.notificationAlert(options);
      });
  };

  return (
    <>
      <div className="full-page section-image">
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Form id="loginForm">
              <Card className="card-login">
                <Card.Header>
                  <h3 className="header text-center">Login</h3>
                </Card.Header>
                <Card.Body>
                  <Form.Group
                    className={
                      "has-label " + (emailState ? "has-success" : "has-error")
                    }
                  >
                    <label>
                      Email Address <span className="star">*</span>
                    </label>
                    <Form.Control
                      name="email"
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (emailValidation(e.target.value)) {
                          setEmailState(true);
                        } else {
                          setEmailState(false);
                        }
                      }}
                    ></Form.Control>
                    {emailState ? null : (
                      <label className="error">This field is required.</label>
                    )}
                  </Form.Group>
                  <Form.Group
                    className={
                      "has-label " +
                      (passwordState ? "has-success" : "has-error")
                    }
                  >
                    <label>
                      Password <span className="star">*</span>
                    </label>
                    <Form.Control
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (minLength(e.target.value, 1)) {
                          setPasswordState(true);
                        } else {
                          setPasswordState(false);
                        }
                      }}
                    ></Form.Control>
                    {passwordState ? null : (
                      <label className="error">This field is required.</label>
                    )}
                  </Form.Group>
                  <div className="card-category form-category">
                    <span className="star">*</span>
                    Required fields
                  </div>
                </Card.Body>
                <Card.Footer className="ml-auto mr-auto">
                  <Button className="btn-wd login-btn" onClick={onSubmit}>
                    Login
                  </Button>
                  

                </Card.Footer>
                <Card.Footer className="ml-auto mr-auto">
                <Row>
                    <NavLink
                      to="/auth/nudge-privacy-policy"
                      className="nav-link"
                      style={{color: "#9A9A9A",fontWeight: "bold"}}
                    >
                      Privacy Policy
                    </NavLink>
                    <NavLink
                      to="/auth/nudge-terms-of-service"
                      className="nav-link"
                      style={{color: "#9A9A9A",fontWeight: "bold"}}
                    >
                      Terms Of Service
                    </NavLink>
                  </Row>
                  

                </Card.Footer>
              </Card>
            </Form>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/nudge-original-3x.png").default +
              ")",
          }}
        ></div>
      </div>
      <NotificationAlert ref={notificationAlertRef} />
    </>
  );
}

export default LoginPage;
