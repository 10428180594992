import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, Modal, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

import ReactTable from "components/ReactTable/ReactTable.js";
import { isRequired } from "../../utils/validators";

function RestaurantsPage() {
  const history = useHistory();
  const [alert, setAlert] = useState();
  const [restaurants, setRestaurants] = useState([]);
  const [selected, setSelected] = useState({});
  const [show, setShow] = useState();
  const [nameState, setNameState] = useState(true);
  const [typeState, setTypeState] = useState(true);
  const [addressState, setAddressState] = useState(true);
  const [cityState, setCityState] = useState(true);
  const [provinceState, setProvinceState] = useState(true);
  const [countryState, setCountryState] = useState(true);
  const [zipcodeState, setZipcodeState] = useState(true);
  const listEl = useRef(null);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
        style: {
          width: 150,
        },
      },
      {
        Header: "Address",
        accessor: (row) =>
          `${row.location.address} ${row.location.city}, ${row.location.province} ${row.location.zipCode}, ${row.location.country}`,
        sortable: true,
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Action",
        Cell: ({ row: { original } }) => (
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="text-warning btn-link edit btn btn-warning btn-sm mb-0"
              onClick={() => openEditDialog(original.id)}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn-link remove text-danger btn btn-danger btn-sm mb-0"
              onClick={() => openDeleteDialog(original.id)}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        ),
        width: 50,
        maxWidth: 50,
      },
    ],
    []
  );

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `/admin/nudgerestaurants`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setRestaurants(response.data.data);
        listEl.current = response.data.data;
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          history.push("/auth/login");
        }
      }
    };

    fetchRestaurants();
  }, []);

  const openNewDialog = () => {
    setSelected(null);
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
  };

  const onSubmit = () => {
    if (selected.id) {
      handleUpdate();
    } else {
      handleCreate();
    }

    setShow(false);
  };

  const openEditDialog = (id) => {
    const currentRests = listEl.current;
    const selectedRes = currentRests.find((res) => res.id === id);
    setSelected(selectedRes);
    setShow(true);
  };

  const handleCreate = async () => {
    const response = await axios({
      method: "POST",
      url: `/admin/nudgerestaurants`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: selected,
    });

    if (response.data.code !== "SUCCESS") return;

    setRestaurants([...restaurants, response.data.data]);
  };

  const handleUpdate = async () => {
    const response = await axios({
      method: "PUT",
      url: `/admin/nudgerestaurants`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: selected,
    });

    if (response.data.code !== "SUCCESS") return;

    const updatedRests = restaurants.map((res) =>
      res.id === selected.id ? selected : res
    );
    setRestaurants(updatedRests);
  };

  const openDeleteDialog = (id) => {
    if (!id) return;

    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDelete(id)}
        onCancel={hideAlert}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this interest!
      </SweetAlert>
    );
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `/admin/nudgerestaurants/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.code !== "SUCCESS") return;

      const currentRests = listEl.current;
      setRestaurants(currentRests.filter((res) => res.id !== id));
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={hideAlert}
          onCancel={hideAlert}
          confirmBtnBsStyle="info"
        >
          This restaurant {id} has been deleted.
        </SweetAlert>
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const checkFormValidate = useCallback(() => {
    if (
      !nameState ||
      !isRequired(selected?.name) ||
      !typeState ||
      !isRequired(selected?.type) ||
      !addressState ||
      !isRequired(selected?.location?.address) ||
      !cityState ||
      !isRequired(selected?.location?.city) ||
      !provinceState ||
      !isRequired(selected?.location?.province) ||
      !countryState ||
      !isRequired(selected?.location?.country) ||
      !zipcodeState ||
      !isRequired(selected?.location?.zipCode)
    ) {
      return false;
    } else {
      return true;
    }
  }, [
    addressState,
    cityState,
    countryState,
    nameState,
    provinceState,
    selected,
    typeState,
    zipcodeState,
  ]);

  return (
    <>
      <Container fluid className="teams-page">
        <h4>Restaurants</h4>
        <div className="d-flex flex-column" ref={listEl}>
          <div className="d-flex justify-content-end mb-2">
            <Button onClick={openNewDialog}>Add New Restaurant</Button>
          </div>
          <ReactTable data={restaurants} columns={columns} />
        </div>
      </Container>
      {alert}

      <Modal show={show} onHide={onClose} size="lg">
        <Form className="mt-4" action="" method="">
          <Modal.Header className="pt-0" closeButton>
            <Modal.Title className="d-flex m-0 w-100 justify-content-center">
              <h4 className="d-flex m-0 align-center">
                {selected ? `Edit` : "New"} Restaurant
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group
                  className={
                    "d-flex flex-column has-label " +
                    (nameState ? "has-success" : "has-error")
                  }
                >
                  <Form.Label className="d-flex mr-2">
                    Name <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    type="text"
                    value={selected?.name || ""}
                    onChange={(e) => {
                      if (e.target.value.length) {
                        setNameState(true);
                      } else {
                        setNameState(false);
                      }

                      setSelected({
                        ...selected,
                        name: e.target.value,
                      });
                    }}
                  ></Form.Control>
                  {nameState ? null : (
                    <label className="error">This field is required.</label>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className={
                    "d-flex flex-column has-label " +
                    (typeState ? "has-success" : "has-error")
                  }
                >
                  <Form.Label className="d-flex mr-2">
                    Type <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    id="type"
                    name="type"
                    type="text"
                    value={selected?.type || ""}
                    onChange={(e) => {
                      if (e.target.value.length) {
                        setTypeState(true);
                      } else {
                        setTypeState(false);
                      }

                      setSelected({
                        ...selected,
                        type: e.target.value,
                      });
                    }}
                  ></Form.Control>
                  {typeState ? null : (
                    <label className="error">This field is required.</label>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="d-flex flex-column has-label">
                  <Form.Label className="d-flex mr-2">Site Url</Form.Label>
                  <Form.Control
                    id="url"
                    name="url"
                    type="text"
                    value={selected?.url || ""}
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        url: e.target.value,
                      });
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="d-flex flex-column has-label">
                  <Form.Label className="d-flex mr-2">Phone</Form.Label>
                  <Form.Control
                    id="phone"
                    name="phone"
                    type="text"
                    value={selected?.phone || ""}
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        phone: e.target.value,
                      });
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className={
                    "d-flex flex-column has-label " +
                    (addressState ? "has-success" : "has-error")
                  }
                >
                  <Form.Label className="d-flex mr-2">
                    Address <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    id="address"
                    name="address"
                    type="text"
                    value={selected?.location?.address || ""}
                    onChange={(e) => {
                      if (e.target.value.length) {
                        setAddressState(true);
                      } else {
                        setAddressState(false);
                      }

                      setSelected({
                        ...selected,
                        location: {
                          ...selected?.location,
                          address: e.target.value,
                        },
                      });
                    }}
                  ></Form.Control>
                  {addressState ? null : (
                    <label className="error">This field is required.</label>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className={
                    "d-flex flex-column has-label " +
                    (cityState ? "has-success" : "has-error")
                  }
                >
                  <Form.Label className="d-flex mr-2">
                    City <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    id="city"
                    name="city"
                    type="text"
                    value={selected?.location?.city || ""}
                    onChange={(e) => {
                      if (e.target.value.length) {
                        setCityState(true);
                      } else {
                        setCityState(false);
                      }

                      setSelected({
                        ...selected,
                        location: {
                          ...selected?.location,
                          city: e.target.value,
                        },
                      });
                    }}
                  ></Form.Control>
                  {cityState ? null : (
                    <label className="error">This field is required.</label>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className={
                    "d-flex flex-column has-label " +
                    (provinceState ? "has-success" : "has-error")
                  }
                >
                  <Form.Label className="d-flex mr-2">
                    State/Province <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    id="province"
                    name="province"
                    type="text"
                    value={selected?.location?.province || ""}
                    onChange={(e) => {
                      if (e.target.value.length) {
                        setProvinceState(true);
                      } else {
                        setProvinceState(false);
                      }

                      setSelected({
                        ...selected,
                        location: {
                          ...selected?.location,
                          province: e.target.value,
                        },
                      });
                    }}
                  ></Form.Control>
                  {provinceState ? null : (
                    <label className="error">This field is required.</label>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className={
                    "d-flex flex-column has-label " +
                    (countryState ? "has-success" : "has-error")
                  }
                >
                  <Form.Label className="d-flex mr-2">
                    Country <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    id="country"
                    name="country"
                    type="text"
                    value={selected?.location?.country || ""}
                    onChange={(e) => {
                      if (e.target.value.length) {
                        setCountryState(true);
                      } else {
                        setCountryState(false);
                      }

                      setSelected({
                        ...selected,
                        location: {
                          ...selected?.location,
                          country: e.target.value,
                        },
                      });
                    }}
                  ></Form.Control>
                  {selected?.location?.country ? null : (
                    <label className="error">This field is required.</label>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className={
                    "d-flex flex-column has-label " +
                    (zipcodeState ? "has-success" : "has-error")
                  }
                >
                  <Form.Label className="d-flex mr-2">
                    Zip Code <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    id="zipCode"
                    name="zipCode"
                    type="text"
                    value={selected?.location?.zipCode || ""}
                    onChange={(e) => {
                      if (e.target.value.length) {
                        setZipcodeState(true);
                      } else {
                        setZipcodeState(false);
                      }

                      setSelected({
                        ...selected,
                        location: {
                          ...selected?.location,
                          zipCode: e.target.value,
                        },
                      });
                    }}
                  ></Form.Control>
                  {selected?.location?.zipCode ? null : (
                    <label className="error">This field is required.</label>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            <Button className="btn-wd" variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="btn-wd"
              variant="primary"
              onClick={onSubmit}
              disabled={!checkFormValidate()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default RestaurantsPage;
