import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {Card, Container, Col } from "react-bootstrap";
import axios from "axios";

function TermsOfService() {   
    const [termsOfService, setTermsOfService] = useState("");

    useEffect(() => {
        axios({
        method: "GET",
        url: `/settings/termsofservice`,
        })
        .then((res) => {    
            setTermsOfService(res.data.data.content);
        })
        .catch((err) => {
            console.log(err);
        });
    }, []); 

    return (
        <>
        <div className="">
            <div className="content pt-5">
                <Container>
                    <Card className="card-login">
                        <Card.Header>
                        <div className="logo">
                            <div className="logo-img">
                            <img
                                src={require("assets/img/nudge-original.png").default}
                                alt="nudge-logo"
                                style={{width: 150}}
                            />
                            </div>
                        </div>
                        </Card.Header>
                        <Card.Body>
                            <div dangerouslySetInnerHTML={{ __html: termsOfService }} className="main-text"></div>                    
                        </Card.Body>
                      
                    </Card>                    
                </Container>
            </div>
            <div
            className="full-page-background"
            style={{
                backgroundImage:
                "url(" +
                require("assets/img/nudge-original-3x.png").default +
                ")",
            }}
            ></div>
        </div>        
        </>
    );
}

export default TermsOfService;
