/*!

=========================================================
* Nudge Admin Portal
=========================================================

* Coded by Jayden Jin

=========================================================

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";
import "assets/scss/styles.scss";
import "assets/css/nudge.css";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { NodeBuilderFlags } from "typescript";
import LoginPage from "./views/Pages/LoginPage";

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers = {
  "Content-Type": "application/json",
};

ReactDOM.render(
  <BrowserRouter>   
    <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      {localStorage.getItem("token") ? (
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      ) : (
        <Redirect to="/auth/login" />
      )}
      <Route path="/auth/login" render={(props) => <LoginPage {...props} />} />
      <Redirect from="/" to="/admin/users" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
