import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Modal, Form } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";

import ReactTable from "components/ReactTable/ReactTable.js";
import { isRequired } from "../../utils/validators";
import { sort } from "../../utils";

function InterestsPage() {
  const history = useHistory();
  const [alert, setAlert] = useState();
  const [interests, setInterests] = useState([]);
  const [selected, setSelected] = useState({});
  const [show, setShow] = useState();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        sortable: true,
        style: { width: "200px" },
      },
      { Header: "Description", accessor: "description", sortable: true },
      {
        Header: "Actions",
        headerClassName: 'headerCls',
        Cell: ({ row }) => (
          <div>
            <button
              type="button"
              className="text-warning btn-link edit btn btn-warning btn-sm"
              onClick={() => openEditDialog(row)}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn-link remove text-danger btn btn-danger btn-sm"
              onClick={() => openDeleteDialog(row)}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        ),
      },
    ],
    [openDeleteDialog, openEditDialog]
  );

  const fetchInterests = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `/admin/interests`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.code !== "SUCCESS" || response.data.data.length <= 0)
        return;

      setInterests(sort(response.data.data, "id"));
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 401) {
        history.push("/auth/login");
      }
    }
  };

  useEffect(() => {
    fetchInterests();
  }, [history]);

  const openEditDialog = useCallback((row) => {
    setSelected({
      id: row.values.id,
      description: row.values.description,
    });
    setShow(true);
  }, []);

  const openDeleteDialog = useCallback((row) => {
    if (!row.values.id) return;

    const id = row.values.id;
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDelete(id)}
        onCancel={hideAlert}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this interest!
      </SweetAlert>
    );
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `/admin/interests/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.code !== "SUCCESS") return;

      setInterests(interests.filter((interest) => interest.id !== id));
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={hideAlert}
          onCancel={hideAlert}
          confirmBtnBsStyle="info"
        >
          This interest {id} has been deleted.
        </SweetAlert>
      );
      fetchInterests();
    } catch (error) {
      console.log(error.response);
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const openNewDialog = () => {
    setSelected(null);
    setShow(true);
  };

  const handleCreate = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `/admin/interests`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: { ...selected },
      });

      if (response.data.code !== "SUCCESS" || !response.data.data) return;

      setInterests([...interests, response.data.data]);
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axios({
        method: "PUT",
        url: `/admin/interests`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: { ...selected },
      });

      if (response.data.code !== "SUCCESS" || !response.data.data) return;

      setInterests(
        interests.map((i) => {
          if (i.id === selected.id) return response.data.data;
          else return i;
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const onClose = () => {
    setShow(false);
  };

  const onSubmit = () => {
    if (!isRequired(selected.description)) return;

    if (selected.id) {
      handleUpdate();
    } else {
      handleCreate();
    }

    setShow(false);
  };

  return (
    <>
      <Container fluid>
        <h4>Interests</h4>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-end mb-2">
            <Button variant="danger" onClick={openNewDialog}>Add New Interest</Button>
          </div>
          {interests && <ReactTable data={interests} columns={columns} />}
        </div>
      </Container>
      {alert}

      <Modal show={show} onHide={onClose} size="lg">
        <Form className="mt-4" action="" method="">
          <Modal.Header className="pt-0" closeButton>
            <Modal.Title className="d-flex m-0 w-100 justify-content-center">
              <h4 className="d-flex m-0 align-center">
                {selected ? `Edit` : "New"} Interest
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group
              className={
                "d-flex flex-column has-label " +
                (selected?.description ? "has-success" : "has-error")
              }
            >
              <Form.Label className="d-flex mr-2">
                Description <span className="star">*</span>
              </Form.Label>
              <Form.Control
                id="description"
                name="description"
                type="text"
                value={selected?.description || ""}
                onChange={(e) => {
                  if (selected) {
                    setSelected({
                      ...selected,
                      description: e.target.value,
                    });
                  } else {
                    setSelected({
                      description: e.target.value,
                    });
                  }
                }}
              ></Form.Control>
              {selected?.description ? null : (
                <label className="error">This field is required.</label>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            <Button className="btn-wd" variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button className="btn-wd" variant="danger" onClick={onSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default InterestsPage;
