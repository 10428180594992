import React, { useState, useRef, useEffect } from "react";
import { Button, Container, Card, Form, Tab, Nav } from "react-bootstrap";
import axios from "axios";
import NotificationAlert from "react-notification-alert";

import { minLength, equalTo } from "../../utils/validators";

function SettingsPage() {
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordState, setOldPasswordState] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordState, setNewPasswordState] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordState, setConfirmPasswordState] = useState(true);
  const notificationAlertRef = useRef(null);
  const defaultNotificationOptions = {
    place: "tr",
    message: "Success",
    type: "success",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 3,
  };

  useEffect(() => {
    if(localStorage.getItem('user')){
      const user = localStorage.getItem('user');
      setEmail(user)
    }
  }, []);

  const handleChange = () => {
    if (!oldPasswordState || !minLength(oldPassword, 1)) {
      setOldPasswordState(false);
    } else {
      setOldPasswordState(true);
    }
    if (!newPasswordState || !minLength(newPassword, 1)) {
      setNewPasswordState(false);
    } else {
      setNewPasswordState(true);
    }
    if (
      !confirmPasswordState ||
      !minLength(confirmPassword, 1) ||
      !equalTo(confirmPassword, newPassword)
    ) {
      setConfirmPasswordState(false);
    } else {
      setConfirmPasswordState(true);
    }

    axios({
      method: "PUT",
      url: "/admin/account/changepassword",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        currentPassword: oldPassword,
        password: newPassword,
        passwordConfirmation: confirmPassword,
      },
    })
      .then((res) => {
        const options = {
          ...defaultNotificationOptions,
          message: "Successfully changed password",
        };
        notificationAlertRef.current.notificationAlert(options);
      })
      .catch((error) => {
        const options = {
          ...defaultNotificationOptions,
          message: "Change error",
          type: "danger",
        };
        notificationAlertRef.current.notificationAlert(options);
      });
  };

  return (
    <>
      <Container fluid>
        <h4>Settings</h4>
        <Card>
          <Card.Body>
            <Tab.Container
              id="plain-tabs-example"
              defaultActiveKey="personal-info"
            >
              <Nav role="tablist" variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="personal-info">Info</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="change-password">Password</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="personal-info">
                  <div className="d-flex my-5 px-4 align-items-center">
                    <Form.Label className="mr-2 mb-0">Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      disabled
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="change-password">
                  <Form
                    className="mt-4"
                    action=""
                    id="ChangePassword"
                    method=""
                  >
                    <Form.Group
                      className={
                        "has-label " +
                        (oldPasswordState ? "has-success" : "has-error")
                      }
                    >
                      <label>
                        Old Password <span className="star">*</span>
                      </label>
                      <Form.Control
                        id="oldPassword"
                        name="oldPassword"
                        type="password"
                        value={oldPassword}
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                          if (minLength(e.target.value, 1)) {
                            setOldPasswordState(true);
                          } else {
                            setOldPasswordState(false);
                          }
                        }}
                      ></Form.Control>
                      {oldPassword ? null : (
                        <label className="error">This field is required.</label>
                      )}
                    </Form.Group>
                    <Form.Group
                      className={
                        "has-label " +
                        (newPasswordState ? "has-success" : "has-error")
                      }
                    >
                      <label>
                        New Password <span className="star">*</span>
                      </label>
                      <Form.Control
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        value={newPassword}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          if (minLength(e.target.value, 1)) {
                            setNewPasswordState(true);
                          } else {
                            setNewPasswordState(false);
                          }
                        }}
                      ></Form.Control>
                      {newPassword ? null : (
                        <label className="error">This field is required.</label>
                      )}
                    </Form.Group>
                    <Form.Group
                      className={
                        "has-label " +
                        (confirmPasswordState ? "has-success" : "has-error")
                      }
                    >
                      <label>
                        Confirm Password <span className="star">*</span>
                      </label>
                      <Form.Control
                        equalto="#newPassword"
                        id="passwordConfirmation"
                        name="password_confirmation"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          if (equalTo(e.target.value, newPassword)) {
                            setConfirmPasswordState(true);
                          } else {
                            setConfirmPasswordState(false);
                          }
                        }}
                      ></Form.Control>
                      {confirmPassword ? null : (
                        <label className="error">
                          This field is required and needs to be equal with the
                          one above.
                        </label>
                      )}
                    </Form.Group>
                    <div className="card-category form-category">
                      <span className="star">*</span>
                      Required fields
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn-fill pull-right"
                        variant="danger"
                        onClick={handleChange}
                      >
                        Change
                      </Button>
                    </div>
                  </Form>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Container>
      <NotificationAlert ref={notificationAlertRef} />
    </>
  );
}

export default SettingsPage;
