import UsersPage from "views/Pages/UsersPage";

import RestaurantsPage from "views/Pages/RestaurantPage";
import InterestPage from "views/Pages/InterestPage";
import UserReportsPage from "views/Pages/UserReportPage";
import PrivacyPolicyPage from "views/Pages/PrivacyPolicyPage";
import TermsOfServicePage from "views/Pages/TermsOfServicePage";
import AboutPage from "views/Pages/AboutPage";
import FeedbackPage from "views/Pages/FeedbackPage";
import SettingsPage from "views/Pages/SettingsPage";
import NotificationsPage from "views/Pages/NotificationsPage";

import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import PrivacyPolicy from "views/Pages/PrivacyPolicy.js";
import TermsOfService from "views/Pages/TermsOfService.js";

const routes = [
  // {
  //   path: "/dashboard",
  //   layout: "/admin",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: DashboardPage,
  // },
  {
    path: "/login",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: LoginPage,
  },  
  {
    path: "/nudge-privacy-policy",
    layout: "/auth",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    redirect: true
  },
  {
    path: "/nudge-terms-of-service",
    layout: "/auth",
    name: "Terms Of Service",
    component: TermsOfService,
    redirect: true
  },
  // {
  //   path: "/restaurants",
  //   layout: "/admin",
  //   name: "Restaurants",
  //   icon: "nc-icon nc-layers-3",
  //   component: RestaurantsPage,
  // },
  {
    path: "/users",
    layout: "/admin",
    name: "Users",
    icon: "nc-icon nc-single-02",
    component: UsersPage,
  },
  {
    path: "/feedback",
    layout: "/admin",
    name: "Feedback",
    icon: "nc-icon nc-backpack",
    component: FeedbackPage,
  },
  {
    path: "/interests",
    layout: "/admin",
    name: "Interests",
    icon: "nc-icon nc-planet",
    component: InterestPage,
  },
  {
    path: "/user-reports",
    layout: "/admin",
    name: "User Reports",
    icon: "nc-icon nc-paper-2",
    component: UserReportsPage,
  },
  // {
  //   path: "/notifications-page",
  //   layout: "/admin",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: NotificationsPage,
  // },

  {
    path: "/settings",
    layout: "/admin",
    name: "Settings",
    icon: "nc-icon nc-settings-gear-64",
    component: SettingsPage,
  },
  {
    path: "/privacy-policy",
    layout: "/admin",
    name: "Privacy Policy",
    icon: "nc-icon nc-notes",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-of-service",
    layout: "/admin",
    name: "Terms of Service",
    icon: "nc-icon nc-grid-45",
    component: TermsOfServicePage,
  },
  // {
  //   path: "/about-app",
  //   layout: "/admin",
  //   name: "About",
  //   icon: "nc-icon nc-app",
  //   component: AboutPage,
  // },
  // {
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       path: "/buttons",
  //       layout: "/admin",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //     },
  //     {
  //       path: "/grid-system",
  //       layout: "/admin",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //     },
  //     {
  //       path: "/panels",
  //       layout: "/admin",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //     },
  //     {
  //       path: "/sweet-alert",
  //       layout: "/admin",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //     },
  //     {
  //       path: "/notifications",
  //       layout: "/admin",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //     },
  //     {
  //       path: "/icons",
  //       layout: "/admin",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //     },
  //     {
  //       path: "/typography",
  //       layout: "/admin",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "nc-icon nc-notes",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       layout: "/admin",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //     },
  //     {
  //       path: "/extended-forms",
  //       layout: "/admin",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //     },
  //     {
  //       path: "/validation-forms",
  //       layout: "/admin",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //     },
  //     {
  //       path: "/wizard",
  //       layout: "/admin",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "nc-icon nc-paper-2",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       layout: "/admin",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //     },
  //     {
  //       path: "/extended-tables",
  //       layout: "/admin",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //     },
  //     {
  //       path: "/react-table",
  //       layout: "/admin",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTables,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "nc-icon nc-pin-3",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       layout: "/admin",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       layout: "/admin",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //     },
  //     {
  //       path: "/vector-maps",
  //       layout: "/admin",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //     },
  //   ],
  // },
  // {
  //   path: "/charts",
  //   layout: "/admin",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  // },
  // {
  //   path: "/calendar-component",
  //   layout: "/admin",
  //   name: "Calendar",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: Calendar,
  // },
  // {
  //   collapse: true,
  //   path: "/pages",
  //   name: "Pages",
  //   state: "openPages",
  //   icon: "nc-icon nc-puzzle-10",
  //   views: [
  //     {
  //       path: "/user",
  //       layout: "/admin",
  //       name: "User Page",
  //       mini: "UP",
  //       component: UserPage,
  //     },
  //     {
  //       path: "/login",
  //       layout: "/auth",
  //       name: "Login Page",
  //       mini: "LP",
  //       component: LoginPage,
  //     },
  //    {
  //      path: "/register",
  //      layout: "/auth",
  //      name: "Register",
  //      mini: "RP",
  //      component: RegisterPage,
  //    },
  //     {
  //       path: "/lock-screen",
  //       layout: "/auth",
  //       name: "Lock Screen Page",
  //       mini: "LSP",
  //       component: LockScreenPage,
  //     },
  //   ],
  // },
];
export default routes;
