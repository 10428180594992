import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Modal, Row, Col, Button, Form } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import axios from "axios";
import Swal from "sweetalert2";

import { USERS } from "../../constants";

function FeedbackPage() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selectedUser, setUser] = useState();
  const [users, setUsers] = useState();
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [userBlocked, setUserBlocked] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);

  const userColumns = [
    {
      Header: "ID",
      accessor: "id",
      sortable: true,
      filterable: false,
      disableFilters: true,
      disableSortBy: true, 
    },
    {
      Header: "First Name",
      accessor: "firstName",
      disableFilters: true,
      disableSortBy: true, 
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      disableFilters: true,
      disableSortBy: true, 
    },
    { 
      Header: "Email", 
      accessor: "email",
      disableFilters: true,
      disableSortBy: true, 
    },
    { 
      Header: "Created At", 
      accessor: "createdAt", 
      sortable: true, 
      disableFilters: true,
      disableSortBy: true,
      canSort: false,
      Cell : (props)=>{
        const custom_date = props.value;        
        if (custom_date !== null ) {
          return <span>{formatDate(custom_date)}</span>
        } else{ return <span>{""}</span> }
      }
    },    
    {
      Header: "Action",
      accessor: "actions",
      style: {textAlign: "right", maxWidth: 30},
      disableFilters: true,
      disableSortBy: true, 
      Cell: ({ row }) => (
        <div>
          <button
            type="button"
            className=" btn-link edit btn  btn-sm"
            onClick={() => openEditDialog(row)}
          >
            <i className="fa fa-eye"></i>
          </button>          
        </div>
      ),
    },
  ];

  const formatDate = (string) => {
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(string).toLocaleDateString([],options);
  }

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `admin/users?Page=${currentPage}&PerPage=${perPage}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        
        if (!response.data.data.items) {          
          return;          
        } 

        setUsers(
          response.data.data.items.map((user) => ({
            ...user,
          }))
        );

        setTotalPages(response.data.data.totalPages);
        setPerPage(response.data.data.perPage);
        setCurrentPage(response.data.data.currentPage);

      } catch (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          history.push("/auth/login");
        }
        setUser();
      }
    };

    fetchUsers();

    const searchUser = async () => {
      try {
        const response = await axios({
          method: "GET",
          // url: `admin/users?Page=${currentPage}&PerPage=${perPage}`,
          url: `admin/users?Query=${encodeURIComponent(search)}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setResults(response.data.data.items);        
        setUsers(
          response.data.data.items.map((user) => ({
            ...user,
          }))
        );

      } catch (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          history.push("/auth/login");
        }
        setUser();
      }
    };

  if ( search && !users.length ) {
      searchUser();      
  }  else {
      const timeoutId = setTimeout(() => {
          if (search) {
              searchUser();
          }
      }, 1000);

      return () => {
          clearTimeout(timeoutId);
      }
  }

  }, [history, currentPage, search, results.length]);


  const changePage = (page)=>{
    // console.log("Page", page)
    setCurrentPage(page)
  }

  const openEditDialog = useCallback((row) => {    
    getUser(row.values.id);   
    setShow(true);
  }, []);

  const getUser = async (index) => {
    try {
      const res = await axios({
        method: "GET",
        url: `/admin/users/${index}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      // console.log("User", res);
      setUser({
        id: index,
        fullName: `${res.data.data.firstName} ${res.data.data.lastName}`,
        userName: res.data.data.userName,
        email: res.data.data.email,
        phone: res.data.data.phoneNumber,
        createdAt: res.data.data.createdAt,
        blockedAt: res.data.data.blockedAt,
        profileImage: res.data.data.profileImage,
      })
      if (res.data.data.blockedAt !== null) {
        setUserBlocked(true);
      } else {setUserBlocked(false)}
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 401) {
        history.push("/auth/login");
      }
    }
  }

  const handleClose = () => setShow(false);

  const onSubmit = () => {
    const index = users.findIndex((user) => user.id === selectedUser.id);
    users[index] = {
      ...selectedUser,
      status: "blocked",
    };

    setUsers(users);
    setShow(false);
  };

  const blockUser = async () => {
    setDisableBtn(true);
    let question = userBlocked ? "Unblock" : "Block";   
    Swal.fire({
      title: "Are you sure?",          
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#FB404B",          
      // cancelButtonColor: "#FB404B",          
      // confirmButtonText: "Block",
      confirmButtonText: question,
      // cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed && !userBlocked ) {
        block();
      } else if (result.isConfirmed && userBlocked ) {
        unblock();
      }
    })
  }
  const block = async () => {
    try {          
      const response = await axios({
        method: "POST",
        url: `/admin/users/${selectedUser?.id}/block/block`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      
      let message = response.data.message;
      if (response.data.code == "SUCCESS" ) {
        Swal.fire({
          title: message,          
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#FB404B",          
          confirmButtonText: "Okay",
        })          
        setShow(false);  
        setDisableBtn(false);
      } 
    } catch (error) {
      console.log(error.responce);
      setDisableBtn(true);
    }
  }
  const unblock = async () => {
    try {          
      const response = await axios({
        method: "POST",
        url: `/admin/users/${selectedUser?.id}/block/unblock`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      
      let message = response.data.message;
      if (response.data.code == "SUCCESS" ) {
        Swal.fire({
          title: message,          
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#FB404B",          
          confirmButtonText: "Okay",
        })          
        setShow(false);  
        setDisableBtn(false);
      } 
    } catch (error) {
      console.log(error.responce);
      setDisableBtn(true);
    }
  }

  return (
    <>
      <Container fluid>
        <h4>Users</h4>
        <div className="d-flex flex-column">

          <div className="form-group" >
              <Form.Group>
                <Form.Label>
                  Search user by first name, last name, email id, account id.
                </Form.Label>
                <Form.Control
                  value={search}
                  className="input"  
                  onChange={ e => setSearch(e.target.value) }
                >
                </Form.Control>  
              </Form.Group>
          </div>

          {users && (
          <ReactTable 
            data={users} 
            columns={userColumns} 
            perPage={perPage}
            currentPage={currentPage}
            totalPages={totalPages}
            changePage={changePage}          
          />
          )}
        </div>
      </Container>

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title className="d-flex m-0 w-100 justify-content-center">
            <h4 className="d-flex m-0 test-center">              
              {selectedUser?.fullName}
            </h4>

            {/* <img src={selectedUser?.profileImage} /> */}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              // className="d-flex justify-content-end align-items-center "
              className="text-right"
              sm={4}  
            >
              <div style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">User ID</div>
            </Col>
            <Col sm={8} >              
              {selectedUser?.id}
            </Col>
          </Row>
          <Row>
            <Col
              className="text-right"
              sm={4}  
            >
              <div style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">Email:</div>
            </Col>
            <Col sm={8}>{selectedUser?.email}</Col>
          </Row>
          <Row>
            <Col
              className="text-right"
              sm={4}  
            >
              <div style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">Phone:</div>
            </Col>
            <Col sm={8}>{selectedUser?.phone}</Col>
          </Row>
          <Row>
            <Col
              className="text-right"
              sm={4}  
            >
              <div style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">Created At:</div>
            </Col>
            <Col sm={8}>
              {formatDate(selectedUser?.createdAt)}, {new Date(selectedUser?.createdAt).toLocaleTimeString('en-US')}           
            </Col>
          </Row>   

          <Row>
            <Col
              className="text-right"
              sm={4}  
            >
              <div style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">Status:</div>
            </Col>
            <Col sm={8}>{selectedUser?.blockedAt !== null ? "Blocked" : "Active" }</Col>
          </Row>
          {selectedUser?.blockedAt !== null && 
            <Row>
              <Col
                className="text-right"
                sm={4}  
              >
                <div style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">Blocked At:</div>
              </Col>
              <Col sm={8}>{formatDate(selectedUser?.blockedAt)}, {new Date(selectedUser?.blockedAt).toLocaleTimeString('en-US')}</Col>
            </Row>          
          }
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn-wd"
            variant="danger"
            onClick={blockUser}
            // disabled={disableBtn}
          >
            {selectedUser?.blockedAt == null ? "Block" : "Unblock" }
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FeedbackPage;
