export const emailValidation = (value) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  );

export const urlValidation = (value) => {
  let returnValue = true;
  try {
    new URL(value);
  } catch (e) {
    returnValue = false;
  } finally {
    return returnValue;
  }
};

export const equalTo = (value1, value2) => value1 === value2;
export const isRequired = (value) => value !== null && value !== "" && value;
export const isNumber = (value) => !isNaN(value) && value !== "";
export const minLength = (value, length) => value.length >= length;
export const maxLength = (value, length) =>
  value.length <= length && value !== "";
export const range = (value, min, max) => min <= value && value <= max;
export const minValue = (value, min) => min <= value;
export const maxValue = (value, max) => max >= value;
