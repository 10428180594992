export const sort = (array, key, type = "number", sortBy = "asc") => {
  if (type === "number") {
    if (sortBy == "asc") {
      return array.sort((a, b) => a[key] - b[key]);
    } else {
      return array.sort((a, b) => b[key] - a[key]);
    }
  } else {
    return array.sort((a, b) => a[key].localeCompare(b[key]));
  }
};
