import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Container, Modal, Row, Col, Button } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import axios from "axios";
import Swal from "sweetalert2";
import Feedback from "react-bootstrap/esm/Feedback";

function FeedbackPage() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selectedFeedback, setFeedback] = useState();
  const [feedbacks, setFeedbacks] = useState();
  const [disableBtn, setDisableBtn] = useState(false);

  const feedbackColumns = [
    {
      Header: "Feedback ID",
      accessor: "id",
      canFilter: false,
      width:'20',
      maxWidth: '20',
    },
    {
      Header: "Category",
      accessor: "categoryName",
      sortable: true,
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      sortable: true,
      Cell : (props)=>{
        const custom_date = props.value;        
        if (custom_date !== null ) {
          return <span>{formatDateTable(custom_date)}</span>
        } else{ return <span>{""}</span> }
      }
    },
    {
      Header: "Description",
      accessor: "body",
      sortable: false,
      filterable: false,
      style: { 'whiteSpace': 'unset' },
      style: {width: '500px'},
      maxWidth: 500,
      minWidth: 350,
      width: 400,
    },
    
    {
      Header: "Action",
      Cell: ({ row }) => (
        <div>
          <button
            type="button"
            className="text-warning btn-link edit btn btn-warning btn-sm"
            onClick={() => openEditDialog(row)}
          >
            <i className="fa fa-eye"></i>
          </button>
          <button
            type="button"
            className="btn-link remove text-danger btn btn-danger btn-sm"
            onClick={() => openDeleteDialog(row)}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      ),
    },
  ];

  const formatDate = (string) => {
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(string).toLocaleDateString([],options);
  }
  const formatDateTable = (string) => {
    let options = { year: 'numeric', month: '2-digit', day: 'numeric' };
    return new Date(string).toLocaleDateString([],options);
  }

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `/platformfeedback`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (!response.data.data.items) {          
          return;          
        } 

        setFeedbacks(
          response.data.data.items.map((feedback) => ({
            ...feedback,
          }))
        );
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          history.push("/auth/login");
        }
        setFeedback();
      }
    };

    fetchFeedback();
  }, [history]);
 
  const getFeedback = async (index) => {
    try {
      const res = await axios({
        method: "GET",
        url: `/platformfeedback/${index}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      
      setFeedback({
        id: index,
        category: res.data.data.categoryName,
        createdAt: res.data.data.createdAt,
        acknowledgedAt: res.data.data.acknowledgedAt,
        title: res.data.data.title,
        body: res.data.data.body,
        acknowledgedBy: res.data.data.acknowledgedBy,
        readAt: res.data.data.readAt,
        submittedBy: res.data.data.submittedBy,
        name: res.data.data.submittedBy.firstName + " " + res.data.data.submittedBy.lastName,
        userName: res.data.data.submittedBy.userName
      });
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 401) {
        history.push("/auth/login");
      }
    }
  }

  const openEditDialog = useCallback((row) => {    
    getFeedback(row.values.id);   
    setShow(true);
  }, []);

  const openDeleteDialog = useCallback((row) => {
    if (!row.values.id) return;
    const id = row.values.id;
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this feedback!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FB404B",
      cancelButtonColor: "#565656",
      confirmButtonText: "Yes, delete!",
    }).then((result) => {
      if(result.isConfirmed) {
        handleDelete(id);
      }
    });
  });

  const handleDelete = async (id) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `/platformfeedback/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.code !== "SUCCESS") return;
      setFeedbacks(feedbacks.filter((feedback) => feedback.id !== id));

      Swal.fire({
        title: "Deleted!",
        icon: "success",
        showCancelButton: false,
      })
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleClose = () => setShow(false);

  const Acknowledge = async () => {
    setDisableBtn(true);
    try {
      const response = await axios({
        method: "POST",
        url: `/platformfeedback/${selectedFeedback?.id}/acknowledge`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      let message = response.data.message;
      if (response.data.code == "SUCCESS" ) {
        Swal.fire({
          title: message,          
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#FB404B",          
          confirmButtonText: "Okay",
        })          
        setShow(false);  
        setDisableBtn(false);
      }       
    } catch (error) {
      console.log(error.response);      
    }
  }
  const Unacknowledge = async () => {
    setDisableBtn(true);
    try {
      const response = await axios({
        method: "POST",
        url: `/platformfeedback/${selectedFeedback?.id}/unacknowledge`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
 
      let message = response.data.message;

      if (response.data.code == "SUCCESS" ) {
        Swal.fire({
          title: message,          
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#FB404B",          
          confirmButtonText: "Okay",
        })          
        setShow(false);
        setDisableBtn(false);
      }      
    } catch (error) {
      console.log(error.response);      
    }
  }

  return (
    <>
      <Container fluid>
        <h4>Feedback</h4>
        <div className="d-flex flex-column">
          {feedbacks && (
            <ReactTable data={feedbacks} columns={feedbackColumns} />
          )}
        </div>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="d-flex m-0 w-100 justify-content-center">
            <h4 className="d-flex m-0 align-center">Feedback</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">
                Category
              </div>
            </Col>
            <Col sm={7} >{selectedFeedback?.category} </Col>
          </Row>
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}} 
                className="text-uppercase font-weight-bold">
                Created At
              </div>
            </Col>
            <Col sm={7} >{formatDate(selectedFeedback?.createdAt)}, {new Date(selectedFeedback?.createdAt).toLocaleTimeString('en-US')}</Col>
          </Row>          
          {selectedFeedback?.acknowledgedAt !== null && 
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}} 
                className="text-uppercase font-weight-bold">
                Acknowledged At
              </div>
            </Col>
            <Col sm={7} >{formatDate(selectedFeedback?.acknowledgedAt)}, {new Date(selectedFeedback?.acknowledgedAt).toLocaleTimeString('en-US')} </Col>
          </Row>
          }
          {selectedFeedback?.acknowledgedBy !== "" && 
          <Row>
            <Col sm={4} >
              <div style={{fontSize: '0.875rem'}} className="text-uppercase font-weight-bold">Acknowledged By</div>
            </Col>
            <Col sm={8} >{selectedFeedback?.acknowledgedBy}</Col>
          </Row>          
          }
          {
            selectedFeedback?.title &&  
            <Row>
              <Col sm={4} >
                <div 
                  style={{fontSize: '0.875rem'}} 
                  className="text-uppercase font-weight-bold">
                  Title
                </div>
              </Col>
              <Col sm={7} >{selectedFeedback?.title} </Col>
            </Row>
          }
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}} 
                className="text-uppercase font-weight-bold">
                Description
              </div>
            </Col>
            <Col sm={7} > <p style={{wordBreak: "break-all"}}> {selectedFeedback?.body} </p> </Col>
          </Row>
          {selectedFeedback?.submittedBy && 
          <Row>
            <Col sm={4} >
              <div style={{fontSize: '0.875rem'}} className="text-uppercase font-weight-bold">Submitted By</div>
            </Col>
            <Col sm={8} > 
              <p> Name: {selectedFeedback?.name} </p>
              <p > Email: {selectedFeedback?.userName} </p>
            </Col>
          </Row>          
          }         
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
        {selectedFeedback?.acknowledgedBy == "" && 
          <Button 
          disabled={disableBtn}
          className="btn-wd" variant="danger" 
          onClick={Acknowledge}
          >
            Acknowledge
          </Button>                   
        }
        {selectedFeedback?.acknowledgedBy !== "" && 
          <Button 
          disabled={disableBtn}
          className="btn-wd" variant="danger" 
          onClick={Unacknowledge}
          >
            Unacknowledge
          </Button>                   
        }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FeedbackPage;
