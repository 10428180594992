import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function PrivacyPolicyPage() {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    axios({
      method: "GET",
      url: `/settings/privacypolicy`,
    })
      .then((res) => {
        const htmlContent = res.data.data.content;
        const contentBlock = htmlToDraft(htmlContent);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleUpdate = async () => {
    setDisableBtn(true);           
    const data = {
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    }

    try {
      const response = await axios({
        method: "PUT",
        url: `/settings/privacypolicy`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: data,
      });

      if (response.data.code !== "SUCCESS" || !response.data.data) return;

      setDisableBtn(false);  

    } catch (error) {
      console.log(error.response);
    }
  }
  
  return (
    <>
      <Container fluid>
        <h4>Privacy Policy</h4>
        <Editor
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
          editorState={editorState}
          toolbarClassName="editor-toolbar"
          wrapperClassName="editor-wrapper"
          editorClassName="editor-content"
          onEditorStateChange={onEditorStateChange}
        />
        <div className="d-flex justify-content-end mt-2">
          <Button variant="danger" onClick={handleUpdate} disabled={disableBtn}>Save</Button>
        </div>
      </Container>
    </>
  );
}

export default PrivacyPolicyPage;
