import React, { useState, useEffect, useCallback } from "react";
import { Container, Modal, Row, Col, Button } from "react-bootstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import axios from "axios";
import Swal from "sweetalert2";

function UserReportsPage() {
  const [reports, setReports] = useState([]);
  const [show, setShow] = useState(false);
  const [report, setReport] = useState([]);
  const [email, setEmail] = useState();
  const [disableBtn, setDisableBtn] = useState(false);

  const columns = [
    {
      Header: "Serial number",
      accessor: "id",
      sortable: true,   
    },    
    { 
      Header: "Reporting User Id", 
      accessor: "reportingUserId", 
      sortable: true,
      show: false,         
    },
    { 
      Header: "Reported", 
      accessor: test => `${test.reportedUser.fullName}, ${test.reportedUser.userName}`, 
      sortable: true,
      show: false,       
    },
    { 
      Header: "Reported User username", 
      accessor: "reportedUser.userName", 
      sortable: true,
      show: false 
    },
    { 
      Header: "Reported User Id", 
      accessor: "reportedUserId", 
      sortable: true,
      show: false
    },
    {
      Header: "Reason",
      accessor: "reason",
      sortable: true,
    },
    { 
      Header: "Reporting", 
      // accessor: "reportingUser.username", 
      accessor: reporting => `${reporting.reportingUser.fullName}, ${reporting.reportingUser.userName}`, 
      sortable: true,
      show: false 
    },
    { 
      Header: "Received date", 
      accessor: "creationDate", 
      sortable: true,
      Cell : (props)=>{        
        const custom_date = props.value;        
        if (custom_date !== null ) {
          return <span>{formatDateTable(custom_date)}</span>
        } else{ return <span>{""}</span> }
      } 
    },
    { 
      Header: "Closed Date", 
      accessor: "closedDate", 
      sortable: true,
      show: false,
      Cell : (props)=>{
        const custom_date = props.value;        
        if (custom_date !== null ) {
          return <span>{formatDateTable(custom_date)}</span>
        } else{ return <span>{"-"}</span> }
      } 
    }, 
    {
      Header: "Status",
      Cell: ({ row }) => <span className={`badge ${row.values.closed ? "success" : "error"}`} >{row.values.closed ? "Closed" : "Active"}</span>,
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <div>
          <button
            type="button"
            className="text-warning btn-link edit btn btn-warning btn-sm"
            onClick={() => openEditDialog(row)}
          >
            <i className="fa fa-eye"></i>
          </button>          
        </div>
      ),
    },
  ];

  const fetchReport = async () => {
    const response = await axios({
      method: "GET",
      url: `/admin/reports`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    
    if (response.data.code !== "SUCCESS" || !response.data.data) return;
    setReports(response.data.data);
  };

  useEffect(() => {  
    fetchReport();
  }, []);

  const openEditDialog = useCallback((row) => {    
       
    setReport(row.values);
    setEmail(row.values.test);   
    setShow(true);
  }, []);

  const handleClose = () => setShow(false);

  const formatDate = (string) => {
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(string).toLocaleDateString([],options);
  }

  const formatDateTable = (string) => {
    let options = { year: 'numeric', month: '2-digit', day: 'numeric' };
    return new Date(string).toLocaleDateString([],options);
  }

  const closeReport = async () => {
    setDisableBtn(true);
    const data = {
      reportId: report.id,
      newStatus: 1,
      reason: report.reason
    }

    try {
      const response = await axios({
        method: "PUT",
        url: `/admin/reports`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: data,
      });
      let message = response.data.message;
      if (response.data.code == "SUCCESS" ) {
        Swal.fire({
          title: message,          
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#FB404B",          
          confirmButtonText: "Okay",
        })          
        setShow(false);  
        setDisableBtn(false);
        fetchReport()
      }
    } catch (error) {
      console.log(error.response);
      setDisableBtn(false);
    }
  }

  return (
    <>
      <Container fluid>
        <h4>User Reports</h4>
        <div className="d-flex flex-column">
          <ReactTable data={reports} columns={columns} initialState
        />
        </div>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="d-flex m-0 w-100 justify-content-center">
            <h4 className="d-flex m-0 align-center">Report Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">
                Reason
              </div>
            </Col>
            <Col sm={7} >{report?.reason} </Col>
          </Row>
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">
                Reported User
              </div>
            </Col>
            <Col sm={7} >{report?.Reported} </Col>
          </Row>
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">
                Reported User ID
              </div>
            </Col>
            <Col sm={7} >{report?.reportedUserId} </Col>
          </Row>
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">
                Reporting User
              </div>
            </Col>
            <Col sm={7} >
              {report?.Reporting}
            </Col>
          </Row>
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">
                Reporting User ID
              </div>
            </Col>
            <Col sm={7} >{report?.reportingUserId} </Col>
          </Row> 
          <Row>
            <Col sm={4} >
              <div 
                style={{fontSize: '0.875rem'}}               
                className="text-uppercase font-weight-bold">
                Received date
              </div>
            </Col>
            {/* <Col sm={7} >{report?.creationDate} </Col> */}
            <Col sm={7} >{formatDate(report?.creationDate)}, {new Date(report?.creationDate).toLocaleTimeString('en-US')} </Col>
          </Row>
          { report?.closedDate && 
            <Row>
              <Col sm={4} >
                <div 
                  style={{fontSize: '0.875rem'}}               
                  className="text-uppercase font-weight-bold">
                  Cloased Date
                </div>
              </Col>
              {/* <Col sm={7} >{report?.creationDate} </Col> */}
              <Col sm={7} >{formatDate(report?.closedDate)}, {new Date(report?.creationDate).toLocaleTimeString('en-US')} </Col>
            </Row>
           }
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
            {report?.Status == undefined && 
              <Button 
              disabled={disableBtn}
              className="btn-wd" variant="danger" 
              onClick={closeReport}
              >
                Close Report
              </Button>                   
            }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserReportsPage;
